<template>
  <div>
  </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>